import React from "react";

// reactstrap components
import {
    Button,
    Input,
    InputGroup,
    Container,
    Row,
    Col,
    Form
} from "reactstrap";

class CreateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(event) {
        var a = this.state;
        console.log(a);
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                title: this.state.title,
                siteName: this.state.siteName,
                email: this.state.email,
                phone: this.state.phone
            })
        };
        fetch('https://api.avav25.net/create_site', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ postId: data.id }));
    }

    render() {
        var form= (
            <Form action="create" className="form">
                <Row>
                    <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                        <InputGroup
                            className= "input-lg input-group-focus"
                        >
                            <Input
                                name="title"
                                placeholder="Title..."
                                type="text"
                                onChange={ (event) => {
                                    this.setState({title: event.target.value});
                                }}
                            ></Input>
                        </InputGroup>
                        <InputGroup
                            className= "input-lg input-group-focus"

                        >
                            <Input
                                name="siteName"
                                placeholder="Site name..."
                                type="text"
                                onChange={ (event) => {
                                    this.setState({siteName: event.target.value});
                                }}
                            ></Input>
                        </InputGroup>
                        <InputGroup
                            className= "input-lg input-group-focus"
                        >
                            <Input
                                name="email"
                                placeholder="Email..."
                                type="text"
                                onChange={ (event) => {
                                    this.setState({email: event.target.value});
                                }}
                            ></Input>
                        </InputGroup>
                        <InputGroup
                            className= "input-lg input-group-focus"
                        >
                            <Input
                                name="phone"
                                placeholder="phone..."
                                type="text"
                                onChange={ (event) => {
                                    this.setState({phone: event.target.value});
                                }}
                            ></Input>
                        </InputGroup>

                        {/*<InputGroup*/}
                        {/*    className= "send-button"*/}
                        {/*>*/}
                        {/*    <Input*/}
                        {/*        type="submit"*/}
                        {/*        value={"Create site"}*/}
                        {/*        onChange={ (event) => {*/}
                        {/*            this.setState({phone: event.target.value});*/}
                        {/*        }}*/}
                        {/*    ></Input>*/}
                        {/*</InputGroup>*/}

                        <div className="send-button">
                            <Button
                                block
                                className="btn-round"
                                color="info"
                                href="#pablo"
                                onClick={this.handleSubmit.bind(this)}
                                size="lg"
                            >
                                Create website
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        );
        return form;
    }
}

function IndexPage() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <div className="wrapper">
                <div className="section section-contact-us text-center">
                    <Container>
                        <h1 className="title">RENTEQY</h1>
                        <h2 className="title">Create rental website in seconds!</h2>
                        <p className="description">Enter just few details and we create site for you.</p>
                        <CreateForm />
                    </Container>
                </div>
                {/*<DefaultFooter />*/}            </div>
        </>
    );
}

export default IndexPage;