import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

import IndexPage from "views/pages/IndexPage"

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/" render={(props) => <IndexPage {...props} />} />
        <Redirect from="/index" to="/" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
